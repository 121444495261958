var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("自定义列表选择")]), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 获取用户列表示例（请求地址url=\"/user/getAll\" 指定绑定的value字段valueBind=\"id\" 指定标题字段title=\"nickname\" 指定备注字段description=\"username\"）： ")]), _c('code', [_vm._v(_vm._s(_vm.customList1))]), _c('br'), _c('br'), _c('div', {
    staticStyle: {
      "font-size": "12px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(" 获取角色列表示例（请求地址url=\"/role/getAllList\" 指定绑定的value字段valueBind=\"name\" 指定标题字段title=\"name\" 指定备注字段description=\"description\"）： ")]), _c('customList', {
    staticStyle: {
      "width": "300px",
      "display": "inline-block",
      "margin-right": "10px"
    },
    attrs: {
      "url": "/role/getAllList",
      "valueBind": "name",
      "title": "name"
    },
    model: {
      value: _vm.customList2,
      callback: function ($$v) {
        _vm.customList2 = $$v;
      },
      expression: "customList2"
    }
  }), _vm._v(" " + _vm._s(_vm.customList2) + " "), _c('br'), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 基本用法，传入 "), _c('code', [_vm._v("请求url")]), _vm._v("、"), _c('code', [_vm._v("绑定值valueBind")]), _vm._v("、"), _c('code', [_vm._v("标题title")]), _vm._v("、"), _c('code', [_vm._v("备注description(可选)")]), _vm._v("属性获取指定接口数据， 使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("数据结构")]), _vm._v(" 列表接口返回的数据结构需满足XBoot框架的规范，至少包含 "), _c('code', [_vm._v("success")]), _vm._v("（成功标志）与 "), _c('code', [_vm._v("result")]), _vm._v("（数据列表）： "), _c('code', [_vm._v("{ success: true/false, result: [] }")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };